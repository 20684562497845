import React from "react";

const NotFound = () => (
  <>
    <h3>Error 404</h3>
    <h5>Elemento no encontrado</h5>
  </>
);

export default NotFound;
