import React from "react";
import "./Footer.scss";

const Footer = () => (
  <footer className="footer">
    <div>
      <p>Made for xxxx 2020</p>
    </div>
  </footer>
);

export default Footer;
