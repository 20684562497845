import React from "react";

const getViewBox = (name) => {
  switch (name) {
    case "github":
      return "0 0 512 512";
    case "google":
      return "0 0 48 48";
    case "twitter":
      return "0 0 128 128";
    case "facebook":
      return "0 0 32 32";
    case "success":
      return "0 0 409 409";
    case "edit":
      return "0 0 406 406";
    case "delete":
      return "0 0 534 534";
    default:
      return "0 0 32 32";
  }
};

const getPath = (name, props) => {
  switch (name) {
    case "github":
      return (
        <path
          {...props}
          d="m256,32c-123.7,0 -224,102.8 -224,229.7c0,101.5 64.2,187.5 153.2,217.9c11.2,2.1 15.3,-5 15.3,-11.1c0,-5.5 -0.2,-19.9 -0.3,-39.1c-62.3,13.9 -75.5,-30.8 -75.5,-30.8c-10.2,-26.5 -24.9,-33.6 -24.9,-33.6c-20.3,-14.3 1.5,-14 1.5,-14c22.5,1.6 34.3,23.7 34.3,23.7c20,35.1 52.4,25 65.2,19.1c2,-14.8 7.8,-25 14.2,-30.7c-49.7,-5.8 -102,-25.5 -102,-113.5c0,-25.1 8.7,-45.6 23,-61.6c-2.3,-5.8 -10,-29.2 2.2,-60.8c0,0 18.8,-6.2 61.6,23.5c17.9,-5.1 37,-7.6 56.1,-7.7c19,0.1 38.2,2.6 56.1,7.7c42.8,-29.7 61.5,-23.5 61.5,-23.5c12.2,31.6 4.5,55 2.2,60.8c14.3,16.1 23,36.6 23,61.6c0,88.2 -52.4,107.6 -102.3,113.3c8,7.1 15.2,21.1 15.2,42.5c0,30.7 -0.3,55.5 -0.3,63c0,6.1 4,13.3 15.4,11c89.2,-30.3 153.3,-116.3 153.3,-217.7c0,-126.9 -100.3,-229.7 -224,-229.7z"
        />
      );
    case "google":
      return (
        <>
          <path
            d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
            id="Fill-1"
            fill="#FBBC05"
          ></path>
          <path
            d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
            id="Fill-2"
            fill="#EA4335"
          ></path>
          <path
            d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
            id="Fill-3"
            fill="#34A853"
          ></path>
          <path
            d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
            id="Fill-4"
            fill="#4285F4"
          ></path>
        </>
      );
    case "twitter":
      return (
        <path
          {...props}
          d="m128,23.294c-4.703,2.142 -9.767,3.59 -15.079,4.237c5.424,-3.328 9.587,-8.606 11.548,-14.892c-5.079,3.082 -10.691,5.324 -16.687,6.526c-4.778,-5.231 -11.608,-8.498 -19.166,-8.498c-14.493,0 -26.251,12.057 -26.251,26.927c0,2.111 0.225,4.16 0.676,6.133c-21.824,-1.126 -41.17,-11.835 -54.131,-28.145c-2.261,3.991 -3.554,8.621 -3.554,13.552c0,9.338 4.636,17.581 11.683,22.412c-4.297,-0.131 -8.355,-1.356 -11.901,-3.359l0,0.331c0,13.051 9.053,23.937 21.074,26.403c-2.201,0.632 -4.523,0.948 -6.92,0.948c-1.69,0 -3.343,-0.162 -4.944,-0.478c3.343,10.694 13.035,18.483 24.53,18.691c-8.986,7.227 -20.315,11.533 -32.614,11.533c-2.119,0 -4.215,-0.123 -6.266,-0.37c11.623,7.627 25.432,12.088 40.255,12.088c48.309,0 74.717,-41.026 74.717,-76.612c0,-1.171 -0.023,-2.342 -0.068,-3.49c5.134,-3.798 9.589,-8.536 13.098,-13.937"
        />
      );
    case "facebook":
      return (
        <path
          {...props}
          d="m18,32l0,-14l6,0l1,-6l-7,0l0,-3c0,-2 1.002,-3 3,-3l3,0l0,-6c-1,0 -3.24,0 -5,0c-5,0 -7,3 -7,8l0,4l-6,0l0,6l6,0l0,14l6,0z"
        />
      );
    case "success":
      return (
        <path
          {...props}
          d="M204.288,0C91.648,0,0,91.648,0,204.288s91.648,204.288,204.288,204.288s204.288-91.648,204.288-204.288
			S316.928,0,204.288,0z M318.464,150.528l-130.56,129.536c-7.68,7.68-19.968,8.192-28.16,0.512L90.624,217.6
			c-8.192-7.68-8.704-20.48-1.536-28.672c7.68-8.192,20.48-8.704,28.672-1.024l54.784,50.176L289.28,121.344
			c8.192-8.192,20.992-8.192,29.184,0C326.656,129.536,326.656,142.336,318.464,150.528z"
        />
      );
    case "edit":
      return (
        <path
          {...props}
          d="m114.6875 284.675781-73.800781-73.800781 178.5-178.5 73.800781 73.800781zm-80.699219-60.800781 67.699219 67.699219-101.5 33.800781zm281.898438-140.300781-12.800781 12.800781-73.898438-73.898438 12.800781-12.800781c12.894531-12.902343 33.804688-12.902343 46.699219 0l27.199219 27.199219c12.800781 12.9375 12.800781 33.765625 0 46.699219zm0 0"
        />
      );
    case "delete":
      return (
        <>
          <path
            {...props}
            d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"
          />
          <path
            {...props}
            d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"
          />
          <path
            {...props}
            d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0"
          />
          <path
            {...props}
            d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"
          />
        </>
      );
    default:
      return <path />;
  }
};

const SVGIcon = ({
  name = "",
  style = {},
  fill = "#000",
  viewBox = "",
  width = "25",
  className = "",
  height = "25",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox || getViewBox(name)}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {getPath(name, { fill })}
  </svg>
);

export default SVGIcon;
